import React, { Component } from "react"
import wa from "../images/wa.png"
import "./style.scss"
class WaButton extends Component {
  render() {
    return <a className="wa-button-wrapper" 
    target="_blank" 
    href="https://api.whatsapp.com/send?phone=62812090373773&text=Saya%20tertarik%20untuk%20membeli%20produk%20ini."
    rel="noopener noreferrer">
      <img src={wa} alt=""  width="60px"/>
    </a>
  }
}

export default WaButton
